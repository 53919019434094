import React, { useEffect, useRef } from "react";
import classes from './IndustryLeading.module.scss';
import left_img from '../../../assets/img/MainPage/IndustryLeading/left_img.png';
import pig from '../../../assets/img/MainPage/IndustryLeading/pig.png';
import { useTranslation } from "react-i18next";
import Cookies from 'js-cookie';
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const IndustryLeading = () => {
    const lang = Cookies.get('i18next');
    const { t } = useTranslation();
    const pigRef = useRef(null);
    const industryLeadingRef = useRef(null);
    useEffect(() => {
        if (document.documentElement.clientWidth > 1220) {
            gsap.timeline().to(pigRef.current, {
                scale: 1,
                scrollTrigger: {
                    trigger: industryLeadingRef.current,
                    end: "+=600px",
                    scrub: 4,
                }
            });
        }
    }, []);
    return (
        <section ref={industryLeadingRef} className={classes.industryLeading}>
            <img className={classes.left_img} src={left_img} alt=''/>
            <img ref={pigRef} className={classes.pig} src={pig} alt=''/>
            <div className="container">
                <div className={classes.industryLeadingBody}>
                    <div className={classes.left}>
                        <h2 className={[classes.title, 'font-64'].join(' ')}>
                            {t('industry_leading_title')}
                        </h2>
                        <p className={[classes.text, 'font-20'].join(' ')}>
                            {t('industry_leading_text')}
                        </p>
                    </div>
                    <div className={classes.right}>
                        <h3 className={[classes.subtitle, 'font-36'].join(' ')}>
                            <span className="font-64">0 </span>
                            {t('industry_leading_subtitle')}
                        </h3>
                        <a href={`https://user.gainground.top/signup?lang=${lang}`} className={classes.btn}>
                            {t('btn_see_more')}
                        </a>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default IndustryLeading;
